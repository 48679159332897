<template>
  <div class="container">
    <div v-if="isLoading">
      <loading></loading>
    </div>
    <div v-else class="page">
      <div><img src="/assets/splash.png"></div>
      <!--
      <div v-if="isVerified" class="card verify-card">
        <span class="material-symbols-outlined">check_circle</span>
        <p>
          This moment is <b>verified</b> by MomentProof technologies. All media captured within this moment as well timestamp and user data is verified.
          You can find below proof regarding this moment.
        </p>
      </div>
      <div v-else class="card verify-card">
        <span class="material-symbols-outlined">warning</span>
        <p>
          This moment can not be verified at the moment by MomentProof technologies. Some part of the data may be altered or changed.
        </p>
      </div>
      -->
      <div v-if="data" class="card info-card">
        <div><span>Title:</span> {{data.title}}</div>
        <div><span>User:</span> {{data.delivered_by}}</div>
        <div><span>Date:</span> {{formatDate(data.created_at)}}</div>
        <div><span>Current Timezone:</span> {{getTimezone()}}</div>
      </div>
      <div style="width: 100%; height: 100%;" id="map">

      </div>
      <div v-if="data && data.files" class="carousel-container">
        <carousel :images="data.files"></carousel>
      </div>
      <div class="legal-notice-container">
        <span>Legal Notice:</span> All media and information displayed here is property of Momentproof. Please check
        www.momentproof.com
      </div>
    </div>
  </div>
</template>

<script lang="js">
import {defineComponent} from 'vue';
import moment from 'moment-timezone';
import Carousel from "@/components/Carousel";
import Loading from "@/components/Loading";
/* global Vue, google */
export default defineComponent({
  name: 'ValidationPage',
  components: {
    Loading,
    Carousel,
  },
  data() {
    return {
      isVerified: false,
      data: null,
      map: null,
      isLoading: true
    }
  },
  async mounted() {
    this.getData();
  },
  methods: {
    async createMap() {
      const myLatLng = { lat: this.data.coordinates[0], lng: this.data.coordinates[1] };
      this.map = new google.maps.Map(document.getElementById("map"), {
        zoom: 16,
        center: myLatLng,
      });
      this.map
      new google.maps.Marker({
        position: myLatLng,
        map: this.map,
        title: "Hello World!",
      });
    },
    formatDate(timestamp) {
      return moment(timestamp._seconds, 'X').format('DD/MM/yyyy HH:mm');
    },
    getTimezone() {
      const timezone = moment.tz(moment.tz.guess());
      return timezone._z.name;
    },
    getData() {
      fetch('https://us-central1-momentproof-dev2.cloudfunctions.net/deliveryFileApi/delivery/'+this.$route.params.id,
          {
            method: 'GET',
            headers: {
              'Access-Control-Allow-Origin': '*'
            }
          })
          .then(response => response.json())
          .then(data => {
            this.data = data;
            this.data.files = this.data.files.map(obj => ({ ...obj, status: 0 }));
            this.isLoading = false;
            setTimeout(() => {
              this.createMap();
            }, 100);
          });
    }
  }
});
</script>

<style scoped>
  .carousel-container {
    width: 100%;
    height: 220px;
  }
  .legal-notice-container {
    border-top: solid 1px black;
    padding-top: 20px;
  }

  .legal-notice-container span {
    font-weight: bold;
  }

  .material-symbols-outlined {
    font-size: 55px;
  }
  .container {
    background-color: white!important;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black!important;
  }
  .container .page {
    width: 100%;
    max-width: 700px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .container .card {
    background-color: #EDEDED;
    border: solid 1px #4B84E1;
    border-radius: 20px;
    width: 100%;
    font-size: 22px;
    display: flex;
    justify-content: center;

    flex-direction: column;
    line-height: 35px;
    margin: 10px 0px;
  }
  .card span {
    font-weight: bold;
  }
  .verify-card {
    align-items: center;
    height: 250px;
  }
  .verify-card p {
    text-align: center;
  }
  .info-card {
    align-items: start;
    padding: 25px;
    height: 200px;
  }

  #map {
    margin: 30px 0px;
  }

  @media screen and (max-width: 767px) {
    .container .page {
      width: calc(100% - 30px);
    }

    .verify-card {
      height: 370px!important;
    }

    .carousel-container {
      height: 140px;
    }

    #map {
      max-height: 200px;
    }
  }

  @media screen and (max-height: 1000px) {
    .container .page {
      min-height: 1000px;
    }
    .container {
      align-items: unset;
      overflow: auto;
    }
  }

</style>
