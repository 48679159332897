<template>
  <div @click="closeDialog($event)"
       v-if="moment"
       class="dialog-container"
       v-show="isOpen">
    <div v-if="moment" :class="moment.type == 'audio/mp4' ? 'audio-dialog' : ''" class="modal-container">

      <div v-if="moment.type.includes('audio')">
        <audio controls>
          <source :src="audioSrc(moment)" type="audio/mp3">
          Your browser does not support the audio element.
        </audio>
      </div>
      <div v-if="moment.type.includes('video')">
        <video :src="videoSrc(moment)" controls></video>
      </div>
      <div v-if="moment.type.includes('image')">
        <img
            :style="{ transform: `translate(${x}px, ${y}px) scale(${scale})` }"
            ref="zoomableImage"
            @click="zoomToImage($event)"
            :class="zoomed ? 'image-zoomed' : ''"
            :id="'moment-image-zoom'" :src="getSrc(moment)" alt="">
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "FilePlayerDialog",
  props: {
    isOpen: Boolean,
    title: String,
    moment: Object,
  },
  data() {
    return {
      isDialogOpen: this.isOpen,
      zoomed: false,
      x: 0, // Horizontal position
      y: 0, // Vertical position
      scale: 1
    }
  },
  methods: {
    zoomToImage(e) {
      this.zoomed = !this.zoomed;

      if (this.zoomed) {
        const { clientX, clientY } = event;
        const { left, top, width, height } = this.$refs.zoomableImage.getBoundingClientRect();

        // Calculate the position of the click relative to the image
        const clickX = clientX - left;
        const clickY = clientY - top;

        // Calculate the scale factor based on the distance from the click to the center of the image
        const centerX = width / 2;
        const centerY = height / 2;
        const distance = Math.sqrt(Math.pow(clickX - centerX, 2) + Math.pow(clickY - centerY, 2));
        const maxDistance = Math.sqrt(Math.pow(centerX, 2) + Math.pow(centerY, 2));
        const scale = 4 - (distance / maxDistance);

        // Update the position and scale of the image
        this.x = (centerX - clickX) * (scale - 1);
        this.y = (centerY - clickY) * (scale - 1);
        this.scale = scale;
      } else {
        this.scale = 1;
        this.x = 0;
        this.y = 0;
      }

    },
    closeDialog(e) {
      if (e.srcElement.classList.contains('dialog-container')) {
        this.$emit('update:isOpen', false);
      }
    },
    videoSrc(videoData) {
      return `https://firebasestorage.googleapis.com/v0/b/momentproof-dev2.appspot.com/o/${videoData.id}?alt=media`;
    },
    audioSrc(audioData) {
      return `https://firebasestorage.googleapis.com/v0/b/momentproof-dev2.appspot.com/o/${audioData.id}?alt=media`;
    },
    getSrc(data) {
      return `https://firebasestorage.googleapis.com/v0/b/momentproof-dev2.appspot.com/o/${data.id}?alt=media`;
    },
  },
};
</script>

<style scoped>
#moment-image-zoom {
  transition: transform 0.2s ease-out;
}

#moment-image-zoom:not(.image-zoomed) {
  cursor: zoom-in;
}

#moment-image-zoom.image-zoomed {
  cursor: zoom-out;
}

.modal-container {
  max-width: 700px;
  width: calc(100vw - 50px);
  background-color: white;
  border-radius: 30px;
  overflow: hidden;
}
.modal-container:not(.audio-dialog) {
  height: calc(100vh - 200px);
}

.audio-dialog {
  height: 54px!important;
}

.audio-dialog audio{
  width: 100%;
}

.modal-container div {
  width: 100%;
  height: 100%;
}

.modal-container div img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

.modal-container div video {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

.dialog-container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;

}
</style>
