<template>
  <div class="carousel">
    <file-player-dialog
        :moment="selectedMoment"
        :is-open="isOpen"
        @update:isOpen="updateDialog"></file-player-dialog>
    <button @click="prev">&lt;</button>
    <div class="images-box">
      <div class="images-container"
      :class="'images-container-' + current + '-' + direction"
      >
        <div class="image-container" v-for="(item, index) in images" :key="'image'+index">
          <span @click="isOpen = true;selectedMoment = item" v-if="item.type.includes('audio')" class="material-symbols-outlined">graphic_eq</span>
          <span @click="isOpen = true;selectedMoment = item" v-if="item.type.includes('video')" class="material-symbols-outlined">videocam</span>
          <img  @click="isOpen = true;selectedMoment = item" v-if="item.type.includes('image')" :src="getSrc(item)" alt="">
          <span>{{formatDate(item.timestamp)}}</span>
          <span class="media-status" :class="getStatus(item)">{{getStatus(item)}}</span>
        </div>
      </div>
    </div>
    <button @click="next">&gt;</button>
  </div>
</template>

<script>
import moment from "moment-timezone";
import FilePlayerDialog from "@/components/FilePlayerDialog";

export default {
  name: "CarouselComponent",
  components: {
    FilePlayerDialog
  },
  props: {
    images: []
  },
  watch: {
    images: {
      handler: function(newVal, oldVal) {
        //console.log('images array changed', newVal, oldVal);
        //newVal.forEach(v => {this.checkVerification(v);});
      },
      deep: true
    }
  },
  data() {
    return {
      isOpen: false,
      selectedMoment: null,
      current: 0,
      direction: 'prev'
    };
  },
  mounted() {
    this.images.forEach(v => {this.checkVerification(v);});
  },
  methods: {
    checkVerification(v) {
      fetch('https://us-central1-momentproof-dev2.cloudfunctions.net/fileValidationApi/validate/'+v.id,
          {
            method: 'GET',
            headers: {
              'Access-Control-Allow-Origin': '*'
            }
          })
          .then(response => response.json())
          .then(data => {
            if (data.isValid) {
              v.status = 1;
            } else {
              v.status = 2;
            }
          });
    },
    getStatus(item) {
      if (item.status == 0) {
        return "verifying";
      } else {
        return item.status == 1 ? "verified" : "error";
      }
    },
    updateDialog(e) {
      this.isOpen = e;
    },
    formatDate(timestamp) {
      return moment(timestamp, 'x').format('DD/MM/yyyy HH:mm');
    },
    getSrc(data) {
      return `https://firebasestorage.googleapis.com/v0/b/momentproof-dev2.appspot.com/o/${data.id}?alt=media`;
    },
    prev() {
      this.current = this.current != 0 ? this.current - 1 : 0;
      this.direction = 'prev';

    },
    next() {
      const val = this.images.length - 3 > 0 ? this.images.length - 3 : 0;
      this.current = this.current != val ? this.current + 1 : val;
      this.direction = 'next';
    }
  }
};
</script>

<style>

.media-status:first-letter {
  text-transform: uppercase;
}

.verifying {
  font-weight: 700;
}

.verifying::after {
  content: "...";
}

.verified {
  font-weight: 700;
  color: green;
}

.error {
  font-weight: 700;
  color: red;
}

.images-box {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  padding-top: 10px;
}



.carousel {
  position: relative;
  width: 100%;
  height: 220px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.carousel .images-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 210px;
  position: absolute;
  top: 0px;
  left: 0px;
}

.images-container-0 {
  left: 0px;
}

.images-container-1-next {
  animation: nextFirst 1.5s forwards;
}

.images-container-0-prev {
  animation: prevFirst 1.5s forwards;
}

.images-container-2-next {
  animation: nextSecond 1.5s forwards;
}

.images-container-1-prev {
  animation: prevSecond 1.5s forwards;
}

.images-container-3-next {
  animation: nextThird 1.5s forwards;
}

.images-container-2-prev {
  animation: prevThird 1.5s forwards;
}



@keyframes nextFirst {
  from {
    left: 0;
  }
  to {
    left: -170px;
  }
}

@keyframes prevFirst {
  from {
    left: -170px;
  }
  to {
    left: 0;
  }
}

@keyframes nextSecond {
  from {
    left: -170px;
  }
  to {
    left: -340px;
  }
}

@keyframes prevSecond {
  from {
    left: -340px;
  }
  to {
    left: -170px;
  }
}

@keyframes nextThird {
  from {
    left: -340px;
  }
  to {
    left: -530px;
  }
}

@keyframes prevThird {
  from {
    left: -530px;
  }
  to {
    left: -340px;
  }
}

.image-container .material-symbols-outlined, .image-container img {
  font-size: 130px;
  color: #4B84E1;
  background-color: #EDEDED;
  border: solid 2px #4B84E1;
  border-radius: 20px;
  margin-bottom: 10px;
  width: 130px;
  height: 130px;
  cursor: pointer;
}

.carousel .images-container .image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 180px;
  min-width: 180px;

  margin: 5px;
}

@media screen and (max-width: 767px) {
  .carousel {
    height: 120px;
  }
  .carousel .images-container {
    height: 130px;
  }

  .image-container .material-symbols-outlined, .image-container img {
    width: 70px;
    height: 70px;
    font-size: 70px;
  }

  .carousel .images-container .image-container {
    height: 100px;
    min-width: 100px;
  }

  @keyframes nextFirst {
    from {
      left: 0;
    }
    to {
      left: -100px;
    }
  }

  @keyframes prevFirst {
    from {
      left: -100px;
    }
    to {
      left: 0;
    }
  }

  @keyframes nextSecond {
    from {
      left: -100px;
    }
    to {
      left: -200px;
    }
  }

  @keyframes prevSecond {
    from {
      left: -200px;
    }
    to {
      left: -100px;
    }
  }

  @keyframes nextThird {
    from {
      left: -200px;
    }
    to {
      left: -300px;
    }
  }

  @keyframes prevThird {
    from {
      left: -300px;
    }
    to {
      left: -200px;
    }
  }
}

.carousel button {
  width: 50px;
  height: 50px;
  font-size: 32px;
  font-weight: bold;
  background: none;
  border: none;
  cursor: pointer;
  color: #4B84E1;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.carousel button:first-child {
  left: 10px;
}

.carousel button:last-child {
  right: 10px;
}
</style>
