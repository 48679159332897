<template>
  <div class="loading">
    <div class="loading-spinner"></div>
    <div class="loading-text">{{ message }}</div>
  </div>
</template>

<script>
export default {
  name: "LoadingComponent",
  props: {
    message: {
      type: String,
      default: 'Loading...'
    }
  }
}
</script>

<style>
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.loading-spinner {
  border: 15px solid #f3f3f3;
  border-top: 15px solid #3498db;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  animation: spin 2s linear infinite;
  margin-right: 10px;
}

.loading-text {
  margin-top: 50px;
  font-size: 16px;
  font-weight: bold;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
